import React from "react"
import Layout from "../components/layout"

import ImageMeta from "../components/ImageMeta"

import { SharedStateProvider } from "../components/SharedState/PageSharedState"

import SEO from "../components/seo"

import Button from "@bit/azheng.joshua-tree.button"
import TheaterVideo from "../components/Theater/TheaterVideo"
import { Link } from "gatsby"

const SuppliesForTeachers = () => {
  let language = "en"

  // active, closed
  let isProgramOpen = false
  // not used with recipent version
  let closedMessage =
    "Thank you for your interest in our Smiles For Teachers program. Our 2020 application period is closed, but stay tuned on our Facebook page to hear about the voting period!"

  return (
    <SharedStateProvider>
      <Layout pageTitle="procedure-page" language={language}>
        <SEO
          title="Learn about our Supplies For Teachers program"
          description="Follow the Supplies For Teachers steps to apply"
        />

        <div className="supplies">
          <img
            className="supplies-banner"
            src="/Programs/supplies-page-banner.jpg"
            alt="Supplies for Teachers banner"
          />

          <div className="supplies-top">
            <h2>
              Giving Teachers and Students <br></br> the supplies they need
            </h2>
          </div>

          <div className="supplies-about">
            <div className="about">
              {/* <h3>About Supplies For Teachers</h3> */}
              <p>
                The team at Oral Surgery & Dental Implant Specialists South
                Carolina is excited to announce the first recipient of their
                Supplies For Teachers program. Mary, a teacher for the deaf and
                hard of hearing program at Charleston County School of the Arts.{" "}
              </p>

              <p>
                Mary was nominated by a fellow teacher to receive extra help
                with supplies because of the outstanding effort and endless
                dedication she brings to her job every day. She goes above and
                beyond helping students in the classroom; she has provided
                homeless students with many resources, given clothing and food
                to students in need, and acted not only as a teacher, but as a
                mother, nurse, therapist, advocate, champion, and more to so
                many children.
              </p>

              <p>
                We are so happy to help Mary and her students get the supplies
                they need including binders, markers, scissors, books, pencils,
                pens, hand soap, and cleaning supplies. We hope this
                contribution will help her and her students continue to succeed
                and thrive this year and beyond.
              </p>

              <ImageMeta
                cloudname="nuvolum"
                publicId={"OSSC/DEV/suppiles-program-recipient-2020"}
                responsive
                className="supplies-recipient-image"
              />
            </div>
            {/* <div className="apply">
                    {!isProgramOpen
                        ? closedMessage
                        : <>
                            <h6>To Apply:</h6>
                            <ul>
                                <li>Fill out the application. </li>
                                <li>Submit completed applications now through November 19, 2020.</li>
                            </ul>
                            <p>If you, or someone you know deserves to be recognized, apply today!</p>
                        </>
                    }
                </div> */}
          </div>

          {!isProgramOpen ? (
            ""
          ) : (
            <div className="supplies-apply-now">
              <p>APPLY NOW!</p>
            </div>
          )}

          <div
            className={`form-wrapper ${
              !isProgramOpen ? "background-white" : ""
            }`}
          >
            <div></div>
            <div>
              <div className="supplies-social">
                <p>
                  Stay tuned to this page,{" "}
                  <a
                    href="https://www.facebook.com/osscarolina/"
                    title="Follow us on Facebook"
                    target="_blank"
                  >
                    Facebook
                  </a>
                  , and{" "}
                  <a
                    href="https://www.instagram.com/oss_carolina/"
                    title="Follow us on Facebook"
                    target="_blank"
                  >
                    Instagram
                  </a>{" "}
                  for program updates!
                </p>
                <p>
                  <i>
                    Learn more about{" "}
                    <a href="/about-our-practice/" title="Learn more about us">
                      Oral Surgery & Dental Implant Specialists South Carolina
                    </a>{" "}
                    and the work they do in the Mount Pleasant{" "}
                    <a
                      href="/community/"
                      title="Learn more about how we help our community"
                    >
                      community!
                    </a>
                  </i>
                </p>
              </div>
            </div>
            <div></div>
          </div>
        </div>
      </Layout>
    </SharedStateProvider>
  )
}

export default SuppliesForTeachers
